import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    color: theme.palette.info.darker,
    backgroundColor: theme.palette.info.lighter,
}));

// const IconWrapperStyle = styled('div')(({ theme }) => ({
//   margin: 'auto',
//   display: 'flex',
//   borderRadius: '50%',
//   alignItems: 'center',
//   width: theme.spacing(8),
//   height: theme.spacing(8),
//   justifyContent: 'center',
//   marginBottom: theme.spacing(3),
//   color: theme.palette.info.dark,
//   backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
//     theme.palette.info.dark,
//     0.24
//   )} 100%)`
// }));

// ----------------------------------------------------------------------

// const TOTAL = 1352831;

export default function AppVerifiedUsers(props) {
    return (
        <RootStyle>
            <Typography
                variant='subtitle2'
                sx={{
                    background: '#fc9f0d',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                }}
            >
                {props.name}
            </Typography>
            <Typography
                variant='h3'
                sx={{
                    background: '#FFFFFF',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                }}
            >
                {props.count === true
                    ? 'Yes'
                    : props.count === false
                    ? 'No'
                    : fShortenNumber(props.count)}
            </Typography>
        </RootStyle>
    );
}
