import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import TaskIcon from '@mui/icons-material/Task';
import LeagueIcon from '@mui/icons-material/SportsEsports';
import BoostIcon from '@mui/icons-material/FlashOn';
import NotificationsIcon from '@mui/icons-material/Notifications';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import SettingsIcon from '@mui/icons-material/Settings';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// ----------------------------------------------------------------------

const getIcon = (IconComponent) => (
    <IconComponent sx={{ width: 22, height: 22, color: 'white' }} />
);

export const techAdminSidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(DashboardIcon),
},
    {
        title: 'Daily Streak',
        path: '/dashboard/streak',
        icon: getIcon(WhatshotIcon),
    },
    {
        title: 'Daily Notification',
        path: '/dashboard/notification',
        icon: getIcon(NotificationsIcon),
    },
    {
      title:'Queue Status',
      path:'/dashboard/queue',
      icon: getIcon(ViewStreamIcon)
    },
  {
      title: 'Demographics',
      path: '/dashboard/DemographicsChart',
      icon: getIcon(GroupsIcon),
  },
  {
      title: 'Admin',
      path: '/dashboard/AddAdmin',
      icon: getIcon(PersonAddIcon),
  },
  {
      title: 'User Details',
      path: '/dashboard/user',
      icon: getIcon(PeopleIcon),
  },
  {
      title: 'Task',
      path: '/dashboard/Task',
      icon: getIcon(TaskIcon),
  },
  {
      title: 'League',
      path: '/dashboard/League',
      icon: getIcon(LeagueIcon),
  },
  {
      title: 'Boost',
      path: '/dashboard/Boost',
      icon: getIcon(BoostIcon),
  },
  {
      title: 'Notification',
      path: '/dashboard/MassNotification',
      icon: getIcon(NotificationsIcon),
  },
  {
      title: 'Partners',
      path: '/dashboard/Partners',
      icon: getIcon(GroupsIcon),
  },
  {
    title: 'RewardDistribution',
    path: '/dashboard/RewardDistribution',
    icon: getIcon(MonetizationOnIcon),
},
{
    title: 'WeekConfigManager',
    path: '/dashboard/WeekConfigManager',
    icon: getIcon(SettingsIcon),
}
];

export const adminsidebarConfig = [
    {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon(DashboardIcon),
    },
    {
        title: 'User Details',
        path: '/dashboard/user',
        icon: getIcon(PeopleIcon),
    },

];

export const superAdminSidebarConfig = [
    {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon(DashboardIcon),
    },
    {
        title: 'Demographics',
        path: '/dashboard/DemographicsChart',
        icon: getIcon(GroupsIcon),
    },
    {
        title: 'Admin',
        path: '/dashboard/AddAdmin',
        icon: getIcon(PersonAddIcon),
    },
    {
        title: 'User Details',
        path: '/dashboard/user',
        icon: getIcon(PeopleIcon),
    },
    {
        title: 'Task',
        path: '/dashboard/Task',
        icon: getIcon(TaskIcon),
    },
    {
        title: 'League',
        path: '/dashboard/League',
        icon: getIcon(LeagueIcon),
    },
    {
        title: 'Boost',
        path: '/dashboard/Boost',
        icon: getIcon(BoostIcon),
    },
    {
        title: 'Notification',
        path: '/dashboard/MassNotification',
        icon: getIcon(NotificationsIcon),
    },
    {
        title: 'Partners',
        path: '/dashboard/Partners',
        icon: getIcon(GroupsIcon),
    },
    {
        title: 'RewardDistribution',
        path: '/dashboard/RewardDistribution',
        icon: getIcon(MonetizationOnIcon),
    },
    {
        title: 'WeekConfigManager',
        path: '/dashboard/WeekConfigManager',
        icon: getIcon(SettingsIcon),
    },
];
