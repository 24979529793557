import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TableHead,
    TableSortLabel,
    TextField,
    Pagination,
    Stack,
    Button,
    Dialog,
    Grid,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    Box,
    MenuItem,
    ListItemIcon,
    ListItemText,
    FormControl,
    InputLabel,
    Select,
    Paper,
    FormControlLabel,
    Switch,
    MenuItem as MuiMenuItem,
    Checkbox,
    CircularProgress,
} from '@mui/material';
import {
    Visibility as VisibilityIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { backendServices } from '../services/backendService';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Download as DownloadIcon } from '@mui/icons-material';
import formatDate from 'src/utils/formatDate';
// --------------------------------------

const TABLE_HEAD = [
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'day', label: 'Day', alignRight: false },
    { id: 'claimInterval', label: 'Claim Interval', alignRight: false },
];

const HISTORY_TABLE_HEAD = [
    { id: 'lastClaim', label: 'Last Claim', alignRight: false },
    { id: 'day', label: 'Day', alignRight: false },
    { id: 'minmul', label: 'Mining Multiplier', alignRight: false },
    { id: 'airdrop', label: 'Airdrop', alignRight: false },
];

const UserStreakList = ({
    refreshTrigger,
    onView,
    onEdit,
    onDelete,
    onToggleBlacklist,
}) => {
    const [ streakData, setStreakData ] = useState([]);
    const [ fullHistory, setFullHistory ] = useState([]);
    const [ userId, setUserId ] = useState(null);
    const [ filterName, setFilterName ] = useState('');
    const [ isBlockedFilter, setIsBlockedFilter ] = useState('all');
    const [ order, setOrder ] = useState('asc');
    const [ orderBy, setOrderBy ] = useState('userName');
    const [ page, setPage ] = useState(1);
    const [ rowsPerPage ] = useState(50);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ selectedUser, setSelectedUser ] = useState(null);
    const [ totalPages, setTotalPages ] = useState(0);
    const [ totalUsers, setTotalUsers ] = useState(0);
    // const [selected, setSelected] = useState([]);
    const [ selectAll, setSelectAll ] = useState(false);
    const [ allSelectedBlocked, setAllSelectedBlocked ] = useState(false);
    const [ loading, setLoading ] = useState();
    const [ showTable, setShowTable ] = useState(false);
    const containerRef = useRef(null);
    const [ tableHeight, setTableHeight ] = useState('100%');
    const [ startDate, setStartDate ] = useState(null);
    const [ endDate, setEndDate ] = useState(null);
    const [ batchSize, setBatchSize ] = useState('');

    const handleInputChange = (event) => {
        setUserId(event.target.value);
    };

    const searchUser = () => {
        setLoading(false);
        setShowTable(true);

        if (userId !== null) {
            fetchStreakData();
        }
    };
    const fetchStreakData = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/dailyCheckIn/check-streak-breaks/${userId}`;
        try {
            const userStreakData = await backendServices.Post(url);
            if (userStreakData) {
                setLoading(false);
            }
            if (userStreakData.status === 200) {
                setStreakData(userStreakData?.data?.streakBreaks);
                setFullHistory(userStreakData?.data?.fullHistory);
                // console.log('Data --> ', userStreakData?.data);
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    useLayoutEffect(() => {
        const updateTableHeight = () => {
            if (containerRef.current) {
                const containerHeight = containerRef.current.clientHeight;
                const toolbarElement = containerRef.current.querySelector('.toolbar');
                const paginationElement = containerRef.current.querySelector('.pagination-container');
                const tableBody = containerRef.current.querySelector('.MuiTableBody-root');
                const toolbarHeight = toolbarElement?.clientHeight || 0;
                const paginationHeight = paginationElement?.clientHeight || 0;
                const tableContentHeight = tableBody?.getBoundingClientRect().height || 0;
                const availableHeight = containerHeight - toolbarHeight - paginationHeight;
                const newTableHeight = Math.min(
                    Math.max(tableContentHeight + 100, 500),
                );

                setTableHeight(`${newTableHeight}px`);
            }
        };
        updateTableHeight();
        const timeoutId = setTimeout(updateTableHeight, 100);
        const handleResize = () => {
            updateTableHeight();
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(timeoutId);
        };
    }, [ streakData, fullHistory ]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div
            ref={containerRef}
            style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    width: '45%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '3%',
                }}
            >
                <TextField
                    label='User Id'
                    name='userId'
                    type='number'
                    onChange={handleInputChange}
                    fullWidth
                    margin='normal'
                    sx={{ width: '75%' }}
                />
                <Button
                    variant='contained'
                    color='primary'
                    onClick={searchUser}
                    sx={{ height: '50%', marginTop: '2%', width: '20%' }}
                >
                    Search
                </Button>
            </Box>
            {showTable ? (
                <Card
                    style={{
                        background: 'black',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {loading ? (
                        <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            height='100vh'
                            marginTop='-50px'
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box sx={{ width: '100%' }}>
                            <Grid
                                container
                                spacing={5}
                                direction={{ xs: 'column', md: 'row' }}
                                sx={{ padding: '5%', display: 'flex', flexWrap: 'wrap' }}
                            >
                                {/* streak break detail table */}

                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    <Typography
                                        variant='h4'
                                        sx={{ marginLeft: '5%' }}
                                        gutterBottom
                                    >
                                        Streak Break
                                    </Typography>
                                    <Paper
                                        sx={{
                                            overflow: 'hidden',
                                            background: 'black',
                                            width: '100%',
                                        }}
                                    >
                                        <TableContainer
                                            sx={{
                                                height: tableHeight,
                                                overflow: 'auto',
                                            }}
                                        >
                                            <Table
                                                stickyHeader
                                                aria-label='sticky table'
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        {TABLE_HEAD.map(
                                                            (headCell) => (
                                                                <TableCell
                                                                    key={
                                                                        headCell.id
                                                                    }
                                                                    align={
                                                                        headCell.alignRight
                                                                            ? 'right'
                                                                            : 'left'
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            '#212121',
                                                                        color: '#fc9f0d',
                                                                    }}
                                                                >
                                                                    <TableSortLabel
                                                                        active={
                                                                            orderBy ===
                                                                            headCell.id
                                                                        }
                                                                        direction={
                                                                            orderBy ===
                                                                                headCell.id
                                                                                ? order
                                                                                : 'asc'
                                                                        }
                                                                    // onClick={() => handleRequestSort(headCell.id)}
                                                                    >
                                                                        {
                                                                            headCell.label
                                                                        }
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                            )
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {streakData.length > 0 ? (
                                                        streakData.map(
                                                            (row) => (
                                                                <TableRow
                                                                    hover
                                                                    key={
                                                                        row.userId
                                                                    }
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    role='checkbox'
                                                                >
                                                                    <TableCell align='left'>
                                                                        {
                                                                            formatDate(row.breakDate)
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        {
                                                                            row.Day
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        {Math.floor(
                                                                            row.timeSinceLastCheck
                                                                        )}{' '}
                                                                        Hrs
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={
                                                                    TABLE_HEAD.length
                                                                }
                                                                align='center'
                                                            >
                                                                No Data found
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Grid>

                                {/* Full History data table */}
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    <Typography
                                        variant='h4'
                                        sx={{ marginLeft: '5%' }}
                                        gutterBottom
                                    >
                                        Full History
                                    </Typography>
                                    <Paper
                                        sx={{
                                            overflow: 'hidden',
                                            background: 'black',
                                        }}
                                    >
                                        <TableContainer
                                            sx={{
                                                height: tableHeight,
                                                overflow: 'auto',
                                            }}
                                        >
                                            <Table
                                                stickyHeader
                                                aria-label='sticky table'
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        {HISTORY_TABLE_HEAD.map(
                                                            (headCell) => (
                                                                <TableCell
                                                                    key={
                                                                        headCell.id
                                                                    }
                                                                    align={
                                                                        headCell.alignRight
                                                                            ? 'right'
                                                                            : 'left'
                                                                    }
                                                                    sortDirection={
                                                                        orderBy ===
                                                                            headCell.id
                                                                            ? order
                                                                            : false
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            '#212121',
                                                                        color: '#fc9f0d',
                                                                    }}
                                                                >
                                                                    <TableSortLabel
                                                                        active={
                                                                            orderBy ===
                                                                            headCell.id
                                                                        }
                                                                        direction={
                                                                            orderBy ===
                                                                                headCell.id
                                                                                ? order
                                                                                : 'asc'
                                                                        }
                                                                    // onClick={() => handleRequestSort(headCell.id)}
                                                                    >
                                                                        {
                                                                            headCell.label
                                                                        }
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                            )
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {fullHistory.length > 0 ? (
                                                        fullHistory.map(
                                                            (row) => (
                                                                <TableRow
                                                                    hover
                                                                    key={
                                                                        row.userId
                                                                    }
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    role='checkbox'
                                                                >
                                                                    <TableCell align='left'>
                                                                        {
                                                                           formatDate(row.lastRewardClaimAt)
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        {
                                                                            row.day
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        {
                                                                            row.miningMultiplier
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align='left'>
                                                                        {
                                                                            row.AirdropPoints
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={
                                                                    HISTORY_TABLE_HEAD.length
                                                                }
                                                                align='center'
                                                            >
                                                                No Data found
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    {/* <Stack
                        spacing={2}
                        direction='row'
                        justifyContent='center'
                        mt={2}
                        className='pagination-container'
                    >
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handleChangePage}
                            color='primary'
                            showFirstButton
                            showLastButton
                        />
                    </Stack> */}
                </Card>
            ) : (
                ''
            )}
        </div>
    );
};

const UserManager = () => {
    const [ refreshTrigger, setRefreshTrigger ] = useState(false);

    return (
        <Container sx={{ margin: '0px !important' }}>
            <Typography variant='h4' gutterBottom>
                Daily Streak
            </Typography>
            <UserStreakList
                refreshTrigger={refreshTrigger}
            // onView={handleView}
            // onEdit={handleEdit}
            // onDelete={handleDelete}
            />
        </Container>
    );
};

export default UserManager;
