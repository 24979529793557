import React, { useState, useEffect } from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  Typography,
  Chip,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Tooltip
} from '@mui/material'
import { backendServices } from '../services/backendService'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { FileUpload as FileUploadIcon } from '@mui/icons-material'

const MiningClaimsDashboard = () => {
  const [weekConfigs, setWeekConfigs] = useState([])
  const [selectedWeek, setSelectedWeek] = useState('')
  const [claimsData, setClaimsData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [openUploadDialog, setOpenUploadDialog] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [txHash, setTxHash] = useState('')
  const [uploadLoading, setUploadLoading] = useState(false)
  const [uploadError, setUploadError] = useState(null)
  const [uploadSummary, setUploadSummary] = useState(null)

  useEffect(() => {
    fetchWeekConfigs()
  }, [])

  useEffect(() => {
    if (selectedWeek) {
      fetchMiningClaims(selectedWeek)
    } else {
      setClaimsData(null)
    }
  }, [selectedWeek])

  const fetchWeekConfigs = async () => {
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/WeekInfo/getAllWeek`
      )
      setWeekConfigs(response.data.weekConfigs)
    } catch (err) {
      setError('Error fetching week configurations')
      console.error('Error:', err)
    }
  }

  const fetchMiningClaims = async week => {
    setLoading(true)
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/WeekInfo/getWeeklyTopUsers/${week}`
      )
      setClaimsData(response.data.data)
      setError(null)
    } catch (err) {
      setError('No mining claims data found.')
      setClaimsData(null)
      console.error('Error:', err)
    } finally {
      setLoading(false)
    }
  }

  const handleWeekChange = event => {
    setSelectedWeek(event.target.value)
  }

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0])
    setUploadError(null)
    setUploadSummary(null)
  }

  const handleUploadSubmit = async () => {
    if (!selectedFile || !txHash) {
      setUploadError('Please fill all required fields')
      return
    }

    setUploadLoading(true)
    setUploadError(null)
    setUploadSummary(null)

    const formData = new FormData()
    formData.append('file', selectedFile)
    formData.append('txHash', txHash)

    try {
      const response = await backendServices.Post(
        `${process.env.REACT_APP_BASE_URL}/WeekInfo/update-rewards`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      if (response.data.success) {
        setUploadSummary(response.data.summary)
        // Close dialog and refresh data only if update was successful
        handleCloseUpload()
        await fetchWeekConfigs() // Refresh week configs to update reward distribution status
        await fetchMiningClaims(selectedWeek) // Refresh claims data
      } else {
        setUploadError(response.data.message || 'Failed to update rewards')
      }
    } catch (error) {
      setUploadError('Failed to update rewards')
      console.error('Error:', error)
    } finally {
      setUploadLoading(false)
    }
  }

  const handleCloseUpload = () => {
    setOpenUploadDialog(false)
    setSelectedFile(null)
    setTxHash('')
    setUploadError(null)
    setUploadSummary(null)
  }

  const isWeekCompleted = () => {
    const selectedWeekConfig = weekConfigs.find(
      config => config.week === selectedWeek
    )
    if (!selectedWeekConfig) return false

    const weekEndDate = new Date(selectedWeekConfig.weekEndDate)
    const currentDate = new Date()
    return currentDate > weekEndDate
  }

  const downloadCSV = () => {
    if (!claimsData || !claimsData.users.length) return

    const headers = ['week', 'userId', 'Points', 'tronAddress', 'reward', 'userName', 'firstName', 'lastName']
    const csvData = claimsData.users.map(user => [
      selectedWeek,
      user.userId,
      user.claimedPoints,
      user.tronAddress,
      user.reward,
      user.userName,
      user.firstName,
      user.lastName
    ])

    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.join(','))
    ].join('\n')

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', `mining_claims_week_${selectedWeek}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  // Check if rewards are already distributed for selected week
  const isRewardDistributed = weekConfigs.find(
    config => config.week === selectedWeek
  )?.isRewardDistributed

  const isDownloadDisabled = !selectedWeek || 
  !claimsData || 
  !claimsData.users.length || 
  !isWeekCompleted()

  const isUploadDisabled = !selectedWeek || 
  isRewardDistributed || 
  !isWeekCompleted()

const getUploadButtonTooltip = () => {
  if (!selectedWeek) return "Please select a week"
  if (isRewardDistributed) return "Rewards already distributed for this week"
  if (!isWeekCompleted()) return "Cannot upload rewards for ongoing weeks"
  return "Upload rewards data"
}

  return (
    <Box sx={{ padding: 3 }}>
      {/* Week Selector and Buttons Container */}
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 3 }}>
        <FormControl fullWidth>
          <InputLabel>Select Week</InputLabel>
          <Select
            value={selectedWeek}
            label='Select Week'
            onChange={handleWeekChange}
          >
            {weekConfigs.map(config => (
              <MenuItem key={config.week} value={config.week}>
                Week {config.week} (
                {new Date(config.weekStartDate).toLocaleDateString()} -{' '}
                {new Date(config.weekEndDate).toLocaleDateString()})
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Tooltip title={
          isDownloadDisabled && selectedWeek ? 
          "Download is disabled for ongoing weeks" : 
          "Download mining claims data"
        }>
          <span>
            <Button
              variant='contained'
              startIcon={<FileDownloadIcon />}
              onClick={downloadCSV}
              disabled={isDownloadDisabled}
              sx={{ minWidth: '150px' }}
            >
              Download CSV
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={getUploadButtonTooltip()}>
          <span>
            <Button
              variant='contained'
              startIcon={<FileUploadIcon />}
              onClick={() => setOpenUploadDialog(true)}
              disabled={isUploadDisabled}
              sx={{ minWidth: '150px' }}
            >
              Upload Rewards
            </Button>
          </span>
        </Tooltip>
      </Box>

      {/* Error Display */}
      {error && (
        <Alert severity='error' sx={{ marginBottom: 2 }}>
          {error}
        </Alert>
      )}

      {/* Loading State */}
      {loading && (
        <Box display='flex' justifyContent='center' padding={4}>
          <CircularProgress />
        </Box>
      )}

      {/* Data Display */}
      {claimsData && !loading && (
        <>
          <Typography variant='h6' gutterBottom>
            Total Users: {claimsData.totalUsers}
          </Typography>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, background: 'black' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Rank</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>user Name</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell align='right'>Points</TableCell>
                  <TableCell align='center'>Claims</TableCell>
                  <TableCell>Tron Address</TableCell>
                  <TableCell>Tx Hash</TableCell>
                  <TableCell align='right'>Reward</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {claimsData.users.map((user, index) => (
                  <TableRow key={user.userId}>
                    <TableCell>#{index + 1}</TableCell>
                    <TableCell>{user.userId}</TableCell>
                    <TableCell>{user.userName}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell align='right'>
                      {user.claimedPoints.toLocaleString()}
                    </TableCell>
                    <TableCell align='center'>
                      <Chip
                        label={user.totalClaimsInWeek}
                        color='primary'
                        size='small'
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {user.tronAddress}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {user.TxHash}
                    </TableCell>
                    <TableCell align='right'>{user.reward}</TableCell>
                    <TableCell>
                      <Chip
                        label={user.TxHash ? 'Processed' : 'Pending'}
                        color={user.TxHash ? 'success' : 'warning'}
                        size='small'
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {/* Empty State */}
      {!loading && !error && !claimsData && selectedWeek && (
        <Alert severity='info'>No data available for the selected week.</Alert>
      )}

      {/* Upload Dialog */}
      <Dialog
        open={openUploadDialog}
        onClose={handleCloseUpload}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle style={{ background: 'black' }}>
          Upload Rewards CSV
        </DialogTitle>
        <DialogContent style={{ background: 'black' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
            <Typography variant='body2' color='textSecondary'>
              CSV should include: userId, week, reward, tronAddress
            </Typography>

            <input
              accept='.csv'
              style={{ display: 'none' }}
              id='raised-button-file'
              type='file'
              onChange={handleFileChange}
            />
            <label htmlFor='raised-button-file'>
              <Button variant='outlined' component='span' fullWidth>
                {selectedFile ? selectedFile.name : 'Choose CSV File'}
              </Button>
            </label>

            <TextField
              label='Transaction Hash'
              value={txHash}
              onChange={e => setTxHash(e.target.value)}
              fullWidth
            />

            {uploadError && <Alert severity='error'>{uploadError}</Alert>}

            {uploadSummary && (
              <Alert
                severity={uploadSummary.failed === 0 ? 'success' : 'warning'}
                sx={{ mt: 2 }}
              >
                <Typography variant='subtitle2'>Upload Summary:</Typography>
                <List dense>
                  <ListItem>
                    <ListItemText
                      primary={`Total Processed: ${uploadSummary.totalProcessed}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={`Successful: ${uploadSummary.successful}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Failed: ${uploadSummary.failed}`} />
                  </ListItem>
                  {uploadSummary.errors?.length > 0 && (
                    <ListItem>
                      <ListItemText
                        primary='Errors:'
                        secondary={
                          <List dense>
                            {uploadSummary.errors.map((error, index) => (
                              <ListItem key={index}>
                                <ListItemText secondary={error} />
                              </ListItem>
                            ))}
                          </List>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </Alert>
            )}
          </Box>
        </DialogContent>
        <DialogActions style={{ background: 'black' }}>
          <Button onClick={handleCloseUpload}>Cancel</Button>
          <Button
            onClick={handleUploadSubmit}
            variant='contained'
            disabled={uploadLoading || !selectedFile || !txHash}
          >
            {uploadLoading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default MiningClaimsDashboard

