import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Checkbox,
    FormControlLabel,
    Card,
    CircularProgress,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { backendServices } from '../services/backendService';
import formatDate from 'src/utils/formatDate';

const DailyNotification = () => {
    const [ open, setOpen ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ sendAt, setSendAt ] = useState(new Date());
    const [ notifications, setNotifications ] = useState([]);
    const [ useDatetime, setUseDatetime ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ isPendingNotification, setIsPendingNotification ] = useState(false);

    useEffect(() => {
        fetchNotifications(currentPage);
    }, [ currentPage ]);

    const fetchNotifications = async (page) => {
        try {
            const response = await backendServices.Get(
                `${process.env.REACT_APP_BASE_URL}/telNotification/getautomessages`
            );
            //  console.log("daily Notification -->",response.data.data);
            setNotifications(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);

            // Check if there's any notification in progress
            const pendingNotification = response.data.data.find(
                (notification) => notification.status === 'In Progress'
            );
            const wasPending = isPendingNotification;
            setIsPendingNotification(!!pendingNotification);

            // If there was a pending notification and now it's completed, show a toast
            if (wasPending && !pendingNotification) {
                toast.success(
                    'Previous notification processing completed. You can now send a new notification.'
                );
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
            toast.error('Error fetching notifications');
        } finally {
            setLoading(false);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (
        <>
            <AppBar
                position='static'
                sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
            >
                <Toolbar>
                    <Typography variant='h4'>
                        Daily Notification Manager
                    </Typography>
                </Toolbar>
            </AppBar>
            <Card
                style={{
                    background: 'black',
                    color: 'white',
                    margin: '20px',
                    height: 'calc(100vh - 40px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {loading ? (
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        height='100vh'
                        marginTop='-50px'
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <TableContainer
                            component={Paper}
                            style={{ background: 'black', color: 'white' }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow style={{ background: '#212121' }}>
                                        <TableCell style={{ color: '#fc9f0d' }}>
                                            Triggered Time
                                        </TableCell>
                                        <TableCell style={{ color: '#fc9f0d' }}>
                                            Completed Time
                                        </TableCell>
                                        <TableCell style={{ color: '#fc9f0d' }}>
                                            Status
                                        </TableCell>
                                        <TableCell style={{ color: '#fc9f0d' }}>
                                            Total Users
                                        </TableCell>
                                        <TableCell style={{ color: '#fc9f0d' }}>
                                            Processed Users
                                        </TableCell>
                                        <TableCell style={{ color: '#fc9f0d' }}>
                                            Failed Users
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {notifications.length > 0 ? (
                                        notifications.map((notification) => (
                                            <TableRow key={notification._id}>
                                                <TableCell
                                                    style={{ color: 'white' }}
                                                >
                                                    {
                                                        formatDate(notification.triggerTimestamp)
                                                    }
                                                </TableCell>
                                                <TableCell
                                                    style={{ color: 'white' }}
                                                >
                                                    {
                                                        formatDate(notification.completedAt)
                                                    }
                                                </TableCell>
                                                <TableCell
                                                    style={{ color: 'white' }}
                                                >
                                                    {notification.status}
                                                </TableCell>
                                                <TableCell
                                                    style={{ color: 'white' }}
                                                >
                                                    {notification.totalUsers}
                                                </TableCell>
                                                <TableCell
                                                    style={{ color: 'white' }}
                                                >
                                                    {
                                                        notification.processedUsers
                                                    }
                                                </TableCell>
                                                <TableCell
                                                    style={{ color: 'white' }}
                                                >
                                                    {notification.failedUsers}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={6}
                                                align='center'
                                            >
                                                No Data found
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </Card>
            <ToastContainer />
        </>
    );
};

export default DailyNotification;
