// this code also working dont remove this code.

import {
    Box,
    Grid,
    Container,
    Typography,
    Chip,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    CircularProgress,
    Skeleton,
    Pagination,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';
import Page from '../components/Page';
import { AppVerifiedUsers } from '../components/_dashboard/app';
import { services } from '../services';
import './dashboardStyle.css';
import DashChartData from 'src/components/charts/DashChartData';
import DemographicsChart from 'src/components/charts/DemographicsChart';
import TaskCompletionChart from 'src/components/charts/TaskCompletionChart';

export default function QueueData() {
    const [data, setData] = useState({
        currentQueueSize: 0,
        runningWorkerCount: 0,
        isQueueFull: false,
        remainingCapacity: 0,
        inProcessUsers: [],
        queueDetails: {
            pendingTasks: 0,
            concurrentTasks: 0,
        },
    });

    const [isLoading, setIsLoading] = useState(true);

    const fetchQueueData = async (start, end) => {
        setIsLoading(true);
        try {
            let queueDataUrl= `${process.env.REACT_APP_BASE_URL}/queue/status`;
            const queueData = await services.Get(
                queueDataUrl
            );

            if (queueData.status === 200) {
                setData(queueData.data.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchQueueData();
    }, []);

    const handleTestQueue = () => {
        fetchQueueData();
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-US').format(number);
    };

    return (
        <Page title='Dashboard | Community Stats'>
            <Container
                maxWidth='xl'
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ pb: 5, textAlign: 'center' }}>
                    <Typography variant='h4' color='white'>
                        Queue Status
                    </Typography>
                </Box>

                <Box
                    sx={{
                        displauy: 'flex',
                        justifyContent: 'end',
                        position: 'absolute',
                        right: '5%',
                    }}
                >
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleTestQueue}
                    >
                        Test Queue
                    </Button>
                </Box>
                {/* General Statistics */}
                <Grid
                    container
                    spacing={3}
                    sx={{ mb: 4 }}
                    justifyContent='flex-start'
                >
                    <Grid item xs={12} sm={6} md={3}>
                        {isLoading ? (
                            <Skeleton
                                variant='rectangular'
                                animation='wave'
                                sx={{ borderRadius: '16px', height: 150 }}
                            />
                        ) : (
                            <AppVerifiedUsers
                                count={formatNumber(data.currentQueueSize)}
                                name='Current Queue Size'
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {isLoading ? (
                            <Skeleton
                                variant='rectangular'
                                animation='wave'
                                className='skeleton'
                                sx={{ borderRadius: '16px', height: 150 }}
                            />
                        ) : (
                            <AppVerifiedUsers
                                name='Running Worker Count'
                                count={formatNumber(data.runningWorkerCount)}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {isLoading ? (
                            <Skeleton
                                variant='rectangular'
                                animation='wave'
                                sx={{ borderRadius: '16px', height: 150 }}
                            />
                        ) : (
                            <AppVerifiedUsers
                                name='Queue Limit Reached'
                                count={data.isQueueFull}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {isLoading ? (
                            <Skeleton
                                variant='rectangular'
                                animation='wave'
                                sx={{ borderRadius: '16px', height: 150 }}
                            />
                        ) : (
                            <AppVerifiedUsers
                                name='Remaining Capacity'
                                count={formatNumber(data.remainingCapacity)}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {isLoading ? (
                            <Skeleton
                                variant='rectangular'
                                animation='wave'
                                sx={{ borderRadius: '16px', height: 150 }}
                            />
                        ) : (
                            <AppVerifiedUsers
                                name='In Process Users'
                                count={formatNumber(data.inProcessUsers.length)}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {isLoading ? (
                            <Skeleton
                                variant='rectangular'
                                animation='wave'
                                sx={{ borderRadius: '16px', height: 150 }}
                            />
                        ) : (
                            <AppVerifiedUsers
                                name='Pending Task'
                                count={formatNumber(
                                    data.queueDetails.pendingTasks
                                )}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        {isLoading ? (
                            <Skeleton
                                variant='rectangular'
                                animation='wave'
                                sx={{ borderRadius: '16px', height: 150 }}
                            />
                        ) : (
                            <AppVerifiedUsers
                                name='Concurrent Task'
                                count={formatNumber(
                                    data.queueDetails.concurrentTasks
                                )}
                            />
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
