import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Snackbar
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { backendServices } from '../services/backendService';

const WeekConfigManager = () => {
  const [weekConfigs, setWeekConfigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  
  const [newConfig, setNewConfig] = useState({
    week: '',
    weekStartDate: null,
    weekEndDate: null
  });

  const fetchWeekConfigs = async () => {
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/WeekInfo/getAllWeek`
      );
      setWeekConfigs(response.data.weekConfigs);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWeekConfigs();
  }, []);

  const addTimeOffset = (date) => {
    if (!date) return null;
    const newDate = new Date(date);
    // Add 5 hours and 30 minutes
    newDate.setHours(newDate.getHours() + 5);
    newDate.setMinutes(newDate.getMinutes() + 30);
    return newDate;
  };

  const formatDateForDB = (date, addOneSecond = false) => {
    if (!date) return null;
    
    // First add the 5:30 offset
    let formattedDate = addTimeOffset(date);
    
    // If addOneSecond is true, add 1 second to the date
    if (addOneSecond) {
      formattedDate.setSeconds(formattedDate.getSeconds() + 1);
    }
    
    // Format the date to ISO string and add the UTC timezone
    return formattedDate.toISOString().slice(0, 19) + '.000+00:00';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (!newConfig.weekStartDate || !newConfig.weekEndDate) {
        return 'Please select both start and end dates';
      }

      // Format dates, adding 1 second to the start date
      const formattedData = {
        week: parseInt(newConfig.week),
        weekStartDate: formatDateForDB(newConfig.weekStartDate, true), // Add 1 second to start date
        weekEndDate: formatDateForDB(newConfig.weekEndDate, false)    // Keep end date as is
      };

      console.log('Sending data with 5:30 offset:', formattedData);

      const response = await backendServices.Post(
        `${process.env.REACT_APP_BASE_URL}/WeekInfo/addWeek`,
        formattedData
      );
      console.log("response======>>>>",response);
      
      if (response.data.success) {
        setSnackbar({
          open: true,
          message: 'Week configuration added successfully',
          severity: 'success'
        });
        fetchWeekConfigs();
        setNewConfig({ week: '', weekStartDate: null, weekEndDate: null });
      } else {
        return response.message || 'Failed to add week configuration';
      }
    } catch (err) {
      console.error('Error:', err);
      setSnackbar({
        open: true,
        message: err.message || 'An error occurred',
        severity: 'error'
      });
    }
  };

  const handleStartDateChange = (date) => {
    console.log('Start date selected:', date);
    setNewConfig(prev => ({ ...prev, weekStartDate: date }));
  };

  const handleEndDateChange = (date) => {
    console.log('End date selected:', date);
    setNewConfig(prev => ({ ...prev, weekEndDate: date }));
  };

  const formatDisplayDate = (dateString) => {
    if (!dateString) return '';
    
    // For display, subtract 5:30 from the stored date to show original selection
    const date = new Date(dateString);
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);
    
    return date.toISOString(); // Or use any other date formatting method you prefer
  };

  const formatDateToUTC = (date) => {
    const utcDate = new Date(date);
    return utcDate.toISOString().replace('T', ' ').slice(0, 19) + ' UTC';
  };

  // Rest of your component remains the same...
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ maxWidth: 1200, margin: '0 auto', p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Week Configurations
        </Typography>

        <Paper 
          sx={{ 
            p: 3, 
            mb: 3, 
            bgcolor: 'black',
            color: 'white'
          }}
          elevation={3}
        >
          <Typography variant="h6" gutterBottom>
            Add New Week Configuration
          </Typography>
          <Box 
            component="form" 
            onSubmit={handleSubmit} 
            sx={{ 
              display: 'flex', 
              gap: 2, 
              flexWrap: 'wrap' 
            }}
          >
            <TextField
              label="Week Number"
              type="number"
              value={newConfig.week}
              onChange={(e) => setNewConfig(prev => ({ ...prev, week: e.target.value }))}
              required
              sx={{
                '& .MuiInputBase-input': { color: 'white' },
                '& .MuiInputLabel-root': { color: 'white' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'white' },
                  '&:hover fieldset': { borderColor: 'white' },
                }
              }}
            />
            <DateTimePicker
              label="Start Date"
              value={newConfig.weekStartDate}
              onChange={handleStartDateChange}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  required
                  sx={{
                    '& .MuiInputBase-input': { color: 'white' },
                    '& .MuiInputLabel-root': { color: 'white' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'white' },
                      '&:hover fieldset': { borderColor: 'white' },
                    }
                  }}
                />
              )}
            />
            <DateTimePicker
              label="End Date"
              value={newConfig.weekEndDate}
              onChange={handleEndDateChange}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  required
                  sx={{
                    '& .MuiInputBase-input': { color: 'white' },
                    '& .MuiInputLabel-root': { color: 'white' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'white' },
                      '&:hover fieldset': { borderColor: 'white' },
                    }
                  }}
                />
              )}
            />
            <Button 
              type="submit" 
              variant="contained" 
              sx={{ height: 56 }}
            >
              Add Configuration
            </Button>
          </Box>
        </Paper>

        <Paper 
          sx={{ 
            bgcolor: 'black',
            color: 'white'
          }}
          elevation={3}
        >
          <Typography variant="h6" sx={{ p: 2 }}>
            Week Configuration List
          </Typography>
          <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell 
                      sx={{ 
                        bgcolor: 'black', 
                        color: 'white',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.2)'
                      }}
                    >
                      Week
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        bgcolor: 'black', 
                        color: 'white',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.2)'
                      }}
                    >
                      Start Date
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        bgcolor: 'black', 
                        color: 'white',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.2)'
                      }}
                    >
                      End Date
                    </TableCell>
                    <TableCell 
                      sx={{ 
                        bgcolor: 'black', 
                        color: 'white',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.2)'
                      }}
                    >
                      Reward Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {weekConfigs.map((config) => (
                    <TableRow key={config._id}>
                      <TableCell sx={{ color: 'white', borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>
                        week-{config.week}
                      </TableCell>
                      <TableCell sx={{ color: 'white', borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>
                        {formatDateToUTC(config.weekStartDate)}
                      </TableCell>
                      <TableCell sx={{ color: 'white', borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>
                        {formatDateToUTC(config.weekEndDate)}
                      </TableCell>
                      <TableCell sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>
                        <Alert 
                          severity={config.isRewardDistributed ? "success" : "warning"}
                          sx={{ 
                            width: 'fit-content',
                            bgcolor: 'transparent',
                            color: config.isRewardDistributed ? '#4caf50' : '#ff9800',
                            '& .MuiAlert-icon': {
                              color: config.isRewardDistributed ? '#4caf50' : '#ff9800'
                            }
                          }}
                        >
                          {config.isRewardDistributed ? 'Distributed' : 'Pending'}
                        </Alert>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert 
            severity={snackbar.severity} 
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default WeekConfigManager;









